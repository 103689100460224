import { useEffect } from "react"
import { navigate } from "@reach/router"

const Error = () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
}

export default Error
